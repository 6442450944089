<template>
    <v-container>
        <v-row justify="center">
            <v-col class="column">
                <group-column
                    :hdlnPairsList="hdlnListColumns.topNews"
                    groupColumnLocation="top-news"
                />
            </v-col>
        </v-row>
        <v-row> 
            <v-col :lg="6" cols="12">
                <group-column
                    :hdlnPairsList="hdlnListColumns.leftColumn"
                    groupColumnLocation="left-column"
                    class="d-flex flex-wrap"
                />
            </v-col>
            <v-col :lg="6" cols="12">
                <group-column
                    :hdlnPairsList="hdlnListColumns.rightColumn"
                    groupColumnLocation="right-column"
                    class="d-flex flex-wrap"
                />
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col>
                <p>Got feedback or tips? Email us at <strong>highnoonreport@protonmail.com</strong></p>
            </v-col>
            <v-col align="right">
                <v-btn @click="$vuetify.goTo('#title-bar')" fab elevation="4" class="to-top-btn">
                    <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
            </v-col>
            
        </v-row>
    </v-container>
</template>

<script>
    import Vue from 'vue';
    import * as allHdlnsJson from '@/data/mainHdlns.json';
    import GroupColumn from './GroupColumn.vue';


  export default Vue.extend({
    name: 'HomeContent',

    components: {
        GroupColumn
    },

    methods: {
        createID(groupTitle) {
                var newID = groupTitle.split(' ').join('-');
                return newID;
        },
    },

    computed: {
        //gets the array of headline pairs from the json file
        hdlnListColumns() {
            return allHdlnsJson.default
        },

    },

    data () {
      return {

      }
    },

  })

    

</script>

<style scoped>
    .column {
        max-width: 1300px;
    }

    .to-top-btn {
        color: var(--v-accent-base)
    }
</style>