<template>
  
    <v-app >
      <v-main class="background">
        <title-bar/>
        <home-content/>
      </v-main>
    </v-app>
  
</template>

<script>
import TitleBar from './components/TitleBar.vue'
import HomeContent from './components/HomeContent.vue'

export default {
  name: 'App',

  components: {
    TitleBar,
    HomeContent
  },

  methods: {
    toggle_dark_mode: function() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>

.background {
  background-color: var(--v-background);
}
</style>
