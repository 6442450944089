import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        // default: 'dark',
        themes: {
          light: {
            primary: colors.grey.lighten2, // #E53935
            secondary: colors.red, // #FFCDD2
            accent: '#C8AC2E', //goldish yellow color of badge
            background: colors.grey.lighten4,
            cardbackground: colors.pink,
            leftheadline: colors.blue.accent4,
            rightheadline: colors.red
          },
          dark: {
            primary: colors.grey.darken3,
            background: colors.grey.darken4,
            cardbackground: colors.purple.darken1,
            leftheadline: colors.blue.lighten1,
            rightheadline: colors.red.accent1
          }
        },
      },
    
    icons: {
      iconfont: 'mdi',
    }
});
