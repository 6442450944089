<template>
    
    <v-card tile elevation="0" class="justify-center" :min-width="minCardWidth">
        <!-- images -->
        <v-row 
            v-if="hasImages"
            v-resize="setImgWidths"
            no-gutters 
            class="mx-3 pt-2 mb-n2"
        >
            <v-col cols="6" class="d-flex justify-center" :ref="leftRefImgParent">
                <v-img 
                    height="auto"
                    :max-width="maxWidthLeftImg"
                    max-height="300px" 
                    :src="hdlnPair.leftData.imageUrl" 
                    :alt="hdlnPair.leftData.imageAlt"
                ></v-img>
            </v-col>
            <v-col cols="6" class="d-flex justify-center" :ref="rightRefImgParent">
                <v-img 
                    height="auto" 
                    :max-width="maxWidthRightImg"
                    max-height="300px" 
                    :src="hdlnPair.rightData.imageUrl" 
                    :alt="hdlnPair.rightData.imageAlt"
                ></v-img>
            </v-col>
        </v-row>
        
        <!-- headlines -->
        <v-row align="center" no-gutters class="mb-n7">
            <v-col class="mx-n2">
                <v-card-text class="text-center">
                    <a 
                        align-center 
                        :class="groupColumnLocation + ' left-headline'" 
                        v-bind:href="hdlnPair.leftData.link" 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        {{hdlnPair.leftData.headline}}
                    </a>
                </v-card-text>
            </v-col>
            <v-divider vertical class="mt-2"></v-divider>
            <v-col class="mx-n2">
                <v-card-text class="text-center">
                    <a 
                        :class="groupColumnLocation + ' right-headline'"
                        v-bind:href="hdlnPair.rightData.link" 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        {{hdlnPair.rightData.headline}}
                    </a>
                </v-card-text>
            </v-col>
        </v-row>


        <!-- bottom data - news source and date posted -->
        <v-row no-gutters class="mt-0 pa-0">
            <v-col>
                <v-row align="center" no-gutters>
                    <v-col cols="5" class="">
                        <v-card-text class="font-italic text--secondary text-no-wrap date-and-source">
                            {{formatDate(hdlnPair.leftData.datePosted).slice(0, 7)}}
                            <br class="d-flex d-sm-none">
                            {{formatDate(hdlnPair.leftData.datePosted).slice(7)}}
                        </v-card-text>
                    </v-col>
                    <v-col cols="7">
                        <v-card-text class="text-right text--secondary text-no-wrap date-and-source">
                            <span v-for="(sourceElement, index) in leftSourceArr" :key="index + Math.random">
                                {{sourceElement}}
                                <br v-if="index != leftSourceArr.length-1" class="d-flex d-sm-none">
                            </span>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-col>
            <v-divider vertical class="mb-2 mt-7"></v-divider>
            <v-col>
                <v-row align="center" no-gutters>
                    <v-col cols="7">
                        <v-card-text class="text--secondary text-no-wrap date-and-source">
                            <span v-for="(sourceElement, index) in rightSourceArr" :key="index + Math.random">
                                {{sourceElement + ' '}}
                                <br v-if="index != rightSourceArr.length-1" class="d-flex d-sm-none date-and-source">
                            </span>
                        </v-card-text>
                    </v-col>
                    <v-col cols="5">
                        <v-card-text class="text-right font-italic text--secondary text-no-wrap date-and-source">
                            {{formatDate(hdlnPair.rightData.datePosted).slice(0, 7)}}
                            <br class="d-flex d-sm-none">
                            {{formatDate(hdlnPair.rightData.datePosted).slice(7)}}
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card> 
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    name: 'HdlnPairCard',

    props: {
        hdlnPair: Object,
        groupColumnLocation: String,
        minCardWidth: Number,
    },

    data() {
        return {
            maxWidthLeftImg: 50,
            maxWidthRightImg: 50,
        }
    },

    computed: {
        leftSourceArr() {
            return this.formatSourceBreaks(this.hdlnPair.leftData.source)
        },

        rightSourceArr() {
            return this.formatSourceBreaks(this.hdlnPair.rightData.source)
        },

        hasImages: function () {
            if (
                this.hdlnPair.leftData.imageUrl
                || this.hdlnPair.rightData.imageUrl
            ) {
                return true
            } else {
                return false
            }
        },

        leftRefImgParent: function () {
            return `${this.hdlnPair._id}-Left-Image-Parent`
        },

        rightRefImgParent: function () {
            return `${this.hdlnPair._id}-Right-Image-Parent`
        },
       
    },

    methods: {
        ///////datestring passed in the headline pair object prop should be an ISO string format
        //formats date to be 'Mon DD, YYYY'
        formatDate(dateString) {
            var newDate = new Date(dateString).toDateString().slice(4);
            newDate = newDate.slice(0, 6) + "," + newDate.slice(6);
            return newDate;
        },

        formatSourceBreaks(source) {
            return source.split(" ");
        },

        setImgWidths() {
            if (this.hasImages) {
                this.maxWidthLeftImg = this.$refs[this.leftRefImgParent].clientWidth;
                this.maxWidthRightImg = this.$refs[this.rightRefImgParent].clientWidth;
            }
        },

        logLeftImgRef() {
            console.log(this.$refs[this.leftRefImgParent].clientWidth)
        }
    },

    mounted() {
        this.$nextTick(function() {
            this.setImgWidths();
        });
    }

  })
</script>

<style scoped>
.left-headline {
    color: var(--v-leftheadline-base);
    font-size: 120%;
    text-decoration: none;
    text-align: justify;
    /* text-justify: inter-word; */
}

.right-headline {
    color: var(--v-rightheadline-base);
    font-size: 120%;
    text-decoration: none;
    text-align: justify;
    /* text-justify: inter-word; */
}

.top-news {
    font-weight: bold;
    font-size: 140%;
}

a:hover {
    text-decoration: underline;
}

.date-and-source {
    font-size: 80%;
}
</style>