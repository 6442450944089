<template>
    <v-card class="mb-1"
        elevation="2" 
        :ref="columnWidthRef"
        v-resize="determineMinCardWidth"
    >
        <div v-for="hdlnPair in hdlnPairsList" :key="hdlnPair._id">
            <hdln-pair-card 
                :hdlnPair="hdlnPair" 
                :groupColumnLocation="groupColumnLocation"
                :minCardWidth="minCardWidth"
            />
            <v-divider class="mx-2"></v-divider>
        </div>
    </v-card>
</template>

<script>
    import Vue from 'vue';
    import HdlnPairCard from './HdlnPairCard.vue';

    export default Vue.extend({
        name: 'GroupColumn',

        components: {
            HdlnPairCard,
        },

        props: {
            hdlnPairsList: Array,
            groupColumnLocation: String,
        },

        methods: {
            determineMinCardWidth() {
                const colWidth = this.$refs[this.columnWidthRef]['$refs'].link.clientWidth;
                this.minCardWidth = colWidth;
                console.log(this.columnWidthRef, this.$refs[this.columnWidthRef]['$refs'].link.clientWidth);
            }
        },

        computed: {    
            columnWidthRef: function() {
                return `${this.groupColumnLocation}-width-ref`
            }
        },

        data () {
            return {
                minCardWidth: 300,
            }
        },

        mounted() {
            this.$nextTick(function() {
                this.determineMinCardWidth();
            });
        }


    })
</script>

<style scoped>

</style>